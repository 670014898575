const appConfig = {
  servicePrefix: {
    staging: {
      auth: "https://apiv2.stagingquamuslms.com/auth",
      school: "https://apiv2.stagingquamuslms.com/school",
      course: "https://apiv2.stagingquamuslms.com/course",
      district: "https://apiv2.stagingquamuslms.com/district",
      quran: "https://apiv2.stagingquamuslms.com/quran",
      activity: "https://apiv2.stagingquamuslms.com/activity",
      finance: "https://apiv2.stagingquamuslms.com/finance",
    },
    production: {
      auth: "https://apiv2.quamuslms.id/auth",
      school: "https://apiv2.quamuslms.id/school",
      course: "https://apiv2.quamuslms.id/course",
      district: "https://apiv2.quamuslms.id/district",
      quran: "https://apiv2.quamuslms.id/quran",
      activity: "https://apiv2.quamuslms.id/activity",
      finance: "https://apiv2.quamuslms.id/finance",
    },
    production2: {
      auth: "https://api.quamuus.com/auth",
      school: "https://api.quamuus.com/school",
      course: "https://api.quamuus.com/course",
      district: "https://api.quamuus.com/district",
      quran: "https://api.quamuus.com/quran",
      activity: "https://api.quamuus.com/activity",
      finance: "https://api.quamuus.com/finance",
      turats: "https://api.quamuus.com/turats",
    },
    // production2: {
    //   auth: "https://api.iyfjsit.com/auth",
    //   school: "https://api.iyfjsit.com/school",
    //   course: "https://api.iyfjsit.com/course",
    //   district: "https://api.iyfjsit.com/district",
    //   quran: "https://api.iyfjsit.com/quran",
    //   activity: "https://api.iyfjsit.com/activity",
    //   finance: "https://api.iyfjsit.com/finance",
    //   turats: "https://api.iyfjsit.com/turats",
    // },
    local: {
      auth: "http://localhost:40030",
      school: "http://localhost:40031",
      course: "http://localhost:40035",
      district: "http://localhost:40036",
      quran: "http://localhost:40033",
      activity: "http://localhost:40032",
      finance: "http://localhost:40034",
      turats: "http://localhost:40037",
    },
  },
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
  stage: "production2",
};

export default appConfig;
