// import BaseService from "./BaseService";

// const ApiService = {
//   fetchData(param) {
//     return new Promise((resolve, reject) => {
//       BaseService(param)
//         .then((response) => {
//           resolve(response);
//         })
//         .catch((errors) => {
//           reject(errors);
//         });
//     });
//   },
// };

// export default ApiService;

import BaseService from "./BaseService";

const ApiService = {
  fetchData(param, retries = 3, delay = 1000) {
    return new Promise((resolve, reject) => {
      const attemptRequest = (attempt) => {
        BaseService(param)
          .then((response) => resolve(response))
          .catch((error) => {
            const statusCode = error?.response?.status || 0;
            console.log("statusCode", statusCode);
            if (parseInt(statusCode) !== 400 && attempt < retries) {
              setTimeout(
                () => attemptRequest(attempt + 1),
                delay * (attempt + 1)
              );
            } else {
              reject(error);
            }
          });
      };

      attemptRequest(0);
    });
  },
};

export default ApiService;
