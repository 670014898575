export const APP_NAME = "PT. Quamus Global Academy";
export const PERSIST_STORE_NAME =
  "dHwKX2v7q4VuhTN5sPY86z3MEbjra9BtcgWZAFQnxLpJGReyCU";
export const REDIRECT_URL_KEY = "redirectUrl";
export const TINY_MCE_UPLOAD_ENDPOINT =
  "https://api.quamuus.com/activity/text_editor/upload";
// export const TINY_MCE_UPLOAD_ENDPOINT =
//   "https://api.iyfjsit.com/activity/text_editor/upload";
export const PERSIST_CBT_QUESTION_NAME =
  "yUPhNMeCAH7cEnYDrd9JSKv4akmbupwZ3XFs85T62VjzBQfLqg";
export const PERSIST_CBT_NAME =
  "e7QhKxLVZmGJACPaukpMR28jBy9YSdTwf6UDv3tEqFHcXbsNg4";
export const SECRET_KEY = "zZCMxbyqteK5ZknWAC6dmZEht7bU7AZA2dWp6EYQ";
